import { Component, Optional, Inject, OnInit } from '@angular/core';
import { Response } from 'express';
import { RESPONSE } from '../../../express.tokens';
import { ContentNotFound } from '../../models/content/content';
import { ContentService } from '../../services/content/content.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  public content: ContentNotFound;
  private response: Response;

  constructor(
    @Optional() @Inject(RESPONSE) response: any,
    private contentService: ContentService
  ) {
    this.response = response;
  }

  ngOnInit() {
    // Get the content
    this.content = this.contentService.getContentByPath('notfound');

    // Response is only available on the server
    if (this.response) {
      // Make this a real server-side 404 or 503
      this.response.status(this.content ? 404 : 503);
    }
  }
}
