import { Inject, Injectable, makeStateKey } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Config } from '../../models/config/config';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: Config;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    @Inject(APP_BASE_HREF) private baseHref: string) { }

  loadConfig(browser: boolean): Observable<Config> {
    const url = `${!browser ? 'http://localhost:4200' : ''}${this.baseHref}assets/config.json`;
    const dataKey = makeStateKey(`configService.loadConfig`);
    const $dataSource = this.http.get<Config>(url);
    return this.utilsService.getServerCachedObservable($dataSource, dataKey)
      .pipe(tap(config => this.config = config));
  }

  get(): Config {
    return this.config;
  }
}
