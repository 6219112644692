import { Inject, Injectable, LOCALE_ID, makeStateKey } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Config } from '../../models/config/config';
import { Observable } from 'rxjs';
import { UtilsService } from '../utils/utils.service';

import { Content } from '../../models/content/content';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private content: Content;

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private http: HttpClient,
    private utilsService: UtilsService,
    private configService: ConfigService
  ) {}

  loadContent(): Observable<any> {
    const url = this.configService.get().contentUrl;
    const dataKey = makeStateKey(`contentService.loadContent`);
    const $dataSource = this.http.get<Config>(url);
    return this.utilsService
      .getServerCachedObservable($dataSource, dataKey)
      .pipe(tap((content) => (this.content = content)));
  }

  isContentLoaded() {
    return this.content !== undefined;
  }

  /** Gets content by path, f.e. `getContentByPath('seo.home.description`) */
  getContentByPath(path: string) {
    return path
      .split('.')
      .reduce((o, k) => o && o[k], this.content?.[this.localeId]);
  }

  /**
   * Returns content for a page by type (f.e. `seo`) and key (f.e. `description`).
   * If no value exists, it returns content from the `default` page.
   */
  getSEOContent(page: string, key: string) {
    return (
      this.content[this.localeId]?.seo[page]?.[key] ||
      this.content[this.localeId]?.seo.default[key]
    );
  }
}
